'use client';

import { Roboto } from 'next/font/google';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { getLocale, Locale } from '@shared/libs';
import { linkStyle } from '@shared/ui';

const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  subsets: ['latin', 'cyrillic'],
});

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  const [locale, setLocale] = useState<Locale | null>(null);

  useEffect(() => {
    const errorText = error.message || error.toString() || '';

    if (errorText.includes('Loading CSS chunk') || errorText.includes('Loading chunk')) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      try {
        const result = await getLocale();
        setLocale(result);
        void fetch('/api-v2/send-log', {
          method: 'POST',
          body: JSON.stringify({
            level: 3,
            msg: JSON.stringify({ error: error.message || error, stack: error.stack?.toString() }),
            href: window.location.href,
            titlePage: document?.title,
            userAgent: navigator?.userAgent,
            digest: error?.digest,
          }),
        });
      } catch (e) {
        console.error(e);
        /* empty */
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <html lang="ru">
      <body>
        <div
          className={roboto.className}
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {locale && (
            <>
              <h1 style={{ margin: 0 }}>{locale.PAGE_ERROR_TITLE}</h1>
              <p style={{ margin: '0 0 16px', maxWidth: 560 }}>{locale.PAGE_ERROR_TEXT}</p>

              <p style={{ margin: '0 0 16px', maxWidth: 600 }}>
                {locale.PAGE_ERROR_PRE_BTN}{' '}
                <Link href="/" prefetch={false} className={linkStyle.link}>
                  {locale.PAGE_ERROR_BTN}
                </Link>
              </p>
            </>
          )}
        </div>
      </body>
    </html>
  );
}
